import axios from 'axios'; 
import { reportError } from './errorReporter';

const STORAGE_KEY = 'tkuid';
const API_HOST = 'https://tiktokvoice.net';

/**
 * 生成匿名用户ID
 * @returns {string} 生成的匿名用户ID
 */
const genTkUid = () => {
    const timestamp = new Date().getTime();
    const random = Math.floor(Math.random() * 10000);
    return `tk${timestamp}${random}`;
};

/**
 * 获取或创建匿名用户ID
 * @returns {string} 匿名用户ID
 */
const getOrCreateTkUid = () => {
    try {
        let uid = localStorage.getItem(STORAGE_KEY);
        if (!uid) {
            uid = genTkUid();
            localStorage.setItem(STORAGE_KEY, uid);
        }
        return uid;
    } catch (error) {
        reportError('Error accessing localStorage:', error);
        // 如果localStorage不可用，返回一个临时ID
        return genTkUid();
    }
};

/**
 * 清除匿名用户ID
 */
const clearTkUid = () => {
    try {
        localStorage.removeItem(STORAGE_KEY);
    } catch (error) {
        reportError('Error removing anonymous uid:', error);
    }
};

/**
 * 获取用户行为计数
 * @param {Object} params - 请求参数
 * @param {string} params.action - 行为类型
 * @param {string} [params.email] - 用户邮箱(可选)
 * @returns {Promise<Object>} 返回请求数据
 * 
 */
async function fetchActionCounts({ action, uid='', email='',  domain='tiktokvoice.net'}) {
    const params = { action, uid, email, domain };

    try {
        const { data } = await axios.get(`${API_HOST}/lapi/actioncounts`, {
            params,
            headers: { 
            'Content-Type': 'application/json; charset=utf-8' 
        },
            timeout: 3000
        });
        console.log('fetchActionCounts - response:' + JSON.stringify(data))
        if (data.ret === 0) {
            return {
                dayCount: data.day_count,
                monthCount: data.month_count
            }
        }
    } catch (error) {
        reportError(error, 'fetchActionCounts error action:' + action + ' uid:' + uid + ' email:' + email + ' domain:' + domain)
    }
    return {
        dayCount: 0,
        monthCount: 0
    }
}

export {
    getOrCreateTkUid,
    fetchActionCounts,
    clearTkUid
};